<template>
    <div id="storie" class="story pt-[50px] scrollable md:pt-[170px]">
        <div class="section-content">

            <h1 class="text-[#96ba64] title-custom section-item text-center euro-stile-std text-[44px] md:text-[63px] leading-[40px] md:leading-[56px]" v-html="localizationData.title"></h1>
            <div class="box section-item  mt-[41px] mb-[63px] bg-[#231a51] max-w-[978px] mx-auto ">
                <div>
                    <div class="px-[45px] md:px-[112px]  outline-offset-[-9px] py-[55px] outline-dashed outline-[18px]  outline-[#96ba64]">
                        <div class="max-w-[763px] ">
                            <h2 class="text-white euro-stile-std  hidden md:block text-[24px] leading-[27px]" v-html="localizationData.box.title_one">

                            </h2>
                            <h2 class="text-white euro-stile-std  sub-custom pl-[20px] md:hidden text-[20px] leading-[27px]" v-html="localizationData.box.title_one_mob">

                            </h2>
                            <p class="text-[#6062a5] mt-[20px] md:mt-[5px] text-[14px] md:text-[15px] euro-stile-std-demi leading-[18px]" v-html="localizationData.box.paragraph_one">

                            </p>
                        </div>
                    </div>

                    <div class="bg-[#96ba64] pb-[130px] mt-[-5px] pt-[55px] md:py-[55px]  px-[45px] md:px-[112px]  outline-[18px] outline-offset-[-9px] outline-dashed outline-[#231a51] relative z-1">
                        <div class="max-w-[763px]">

                            <h2 class="text-white euro-stile-std  hidden md:block  text-[24px] leading-[27px]" v-html="localizationData.box.title_two">

                            </h2>
                            <h2 class="text-white euro-stile-std pl-[20px] md:hidden   sub-custom text-[20px] leading-[27px]" v-html="localizationData.box.title_two_mob">
                            </h2>

                            <p class="text-[#231a51] mt-[20px] md:mt-[5px] text-[14px]  md:text-[15px] euro-stile-std-demi leading-[18px]" v-html="localizationData.box.paragraph_two">

                            </p>
                        </div>
                    </div>

                    <div class="pb-[70px] pt-[55px] mt-[-2px] relative md:py-[55px]  px-[45px] md:px-[112px] outline-offset-[-9px] py-[55px] outline-dashed outline-[18px]  outline-[#96ba64]">
                        <div class="max-w-[763px]">

                            <h2 class="text-white hidden md:block  euro-stile-std   text-[24px] leading-[27px]" v-html="localizationData.box.title_three">

                            </h2>

                            <h2 class="text-white md:hidden sub-custom  euro-stile-std  pl-[25px] text-[20px] leading-[27px]" v-html="localizationData.box.title_three_mob">

                            </h2>
                            <p class="text-[#6062a5]  mt-[20px] md:mt-[5px] text-[14px] md:text-[15px] euro-stile-std-demi leading-[18px]" v-html="localizationData.box.paragraph_three">

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="img section-item min-h-[129px] max-h-[200px] h-[100%] md:h-[100%]">
                <img class="md:max-w-[990px] mb-[-1px] max-h-[200px]   mx-auto section-image" :src="getImgUrl('2')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['localizationData'],

    methods: {
        getImgUrl(img) {
            const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (windowWidth < 768) {
                return new URL('/images/Mobile/' + img + ".png", import.meta.url)
            } else {
                return new URL('/images/Desktop/' + img + ".png", import.meta.url)

            }
        }

    }
}
</script>

<style></style>