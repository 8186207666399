import { createApp } from "vue";
import "./style.css";
import "./assets/style/index.css";
import "./assets/style/app.less";
import store from "./store";
import App from "@/App.vue";
import * as Sentry from "@sentry/vue";

const app = createApp(App);
app.use(store);

Sentry.init({
    app,
    dsn: "https://e9d8317a6993241cf38367e39d21c0a6@o122991.ingest.us.sentry.io/4507667311689728",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount("#app");
