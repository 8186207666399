<template>
    <div class="lead-per-crescere pt-[50px] md:pt-[170px] scrollable" id="lead-crescere">
        <div class="section-content">

            <h1 class="text-[#96ba64] title-custom section-item text-center euro-stile-std text-[44px] md:text-[63px] leading-[40px] md:leading-[56px]" v-html="localizationData.title"></h1>
            <div class="box px-[45px] section-item md:px-[112px] pt-[50px] pb-[80px] md:py-[64px] mt-[41px] mb-[63px] bg-[#6062a5] max-w-[978px] mx-auto outline-[18px] outline-offset-[-9px] outline-dashed outline-[#96ba64]">
                <div class="max-w-[763px]">
                    <h2 class="text-white hidden md:block euro-stile-std text-[24px] leading-[27px]" v-html="localizationData.box.title_one">

                    </h2>
                    <h2 class="text-white pl-[25px] md:hidden sub-custom euro-stile-std text-[24px] leading-[27px]" v-html="localizationData.box.title_one_mob">
                    </h2>
                    <p class="text-[#231a51] mt-[5px] hidden md:block euro-stile-std-demi text-[15px] leading-[18px]" v-html="localizationData.box.paragraph_one">

                    </p>
                    <p class="text-[#231a51] mt-[20px]  md:hidden  text-[14px] leading-[18px]" v-html="localizationData.box.paragraph_one_mob">

                    </p>
                    <p class="text-[#231a51] mt-[20px]  md:hidden  text-[14px] leading-[18px]" v-html="localizationData.box.paragraph_two_mob">


                    </p>
                    <p class="text-[#231a51] mt-[20px] md:hidden  text-[14px] leading-[18px]" v-html="localizationData.box.paragraph_three_mob">

                    </p>

                    <h2 class="text-white hidden md:block mt-[31px] euro-stile-std  text-[24px] leading-[27px]" v-html="localizationData.box.title_two">


                    </h2>

                    <p class="text-[#231a51] hidden md:block mt-[5px] text-[14px] euro-stile-std-demi leading-[18px]" v-html="localizationData.box.paragraph_two">
                    </p>

                    <h2 class="text-white mt-[31px] hidden md:block  euro-stile-std text-[24px] leading-[27px]" v-html="localizationData.box.title_three">

                    </h2>
                    <p class="text-[#231a51] hidden md:block mt-[5px] text-[15px] euro-stile-std-demi leading-[18px]" v-html="localizationData.box.paragraph_three">
                    </p>
                </div>

            </div>

            <div class="img section-item min-h-[129px] max-h-[200px] h-[100%] md:h-[100%]">
                <img class="md:max-w-[990px] mb-[-1px] max-h-[200px] mx-auto section-image top-appeared" :src="getImgUrl('1')" alt="">
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['localizationData'],
    methods: {
        getImgUrl(img) {
            const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (windowWidth < 768) {
                return new URL('/images/Mobile/' + img + ".png", import.meta.url)
            } else {
                return new URL('/images/Desktop/' + img + ".png", import.meta.url)

            }
        }

    }
}
</script>

<style></style>