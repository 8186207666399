<template>
    <div class="growth pt-[50px] md:pt-[93px] pb-[40px] md:pb-[81px]">
        <h1 class="text-[#6062a5] title-custom text-center euro-stile-std  text-[44px] md:text-[63px] leading-[40px] md:leading-[56px]" v-html="localizationData.title"></h1>
        <div class="box  pt-[77px] px-[50px] md:px-[81px] pb-[67px] mt-[31px] mb-[63px] bg-[#6062a5] max-w-[1300px] mx-auto outline-[18px] outline-offset-[-9px] outline-dashed outline-[#231a51]">
            <div class="max-w-[1300px]">
                <h2 class="text-white euro-stile-std sub-custom text-[23px] pl-[25px] md:pl-0 leading-[27px]" v-html="localizationData.box.title_one">
                </h2>
                <p class="text-[#231a51] md:mt-[10px] euro-stile-std-demi  text-[17px] md:text-[20px] italic leading-[20px] mt-[10px]" v-html="localizationData.box.paragraph_one">
                </p>

                <h2 class="text-white mt-[31px] sub-custom euro-stile-std  text-[24px] pl-[25px] md:pl-0 leading-[27px]" v-html="localizationData.box.title_two">
                </h2>

                <p class="text-[#231a51] md:mt-[10px] text-[17px] md:text-[20px] italic euro-stile-std-demi leading-[20px] mt-[10px]" v-html="localizationData.box.paragraph_two">
                </p>
                <p class="text-[#231a51] md:mt-[10px] text-[17px] md:text-[20px] italic euro-stile-std-demi leading-[20px] mt-[10px]">
                    <span v-html="localizationData.box.paragraph_three"></span> <button @click='isForm = true'><span class='font-[700] text-[#96ba64]' v-html="localizationData.box.button_click"></span></button> <span v-html="localizationData.box.paragraph_four"></span>
                </p>
            </div>
            <Form v-if="isForm" :localizationData="localizationData.form" />
        </div>
    </div>
</template>

<script>
import Form from './Form.vue'
export default {
    components: {
        Form
    },
    props: ['localizationData'],
    data() {
        return {
            isForm: false
        }
    }

}
</script>

<style></style>