<template>
  <Home />
</template>

<script>
import Home from '@/components/Home.vue'
export default {
  components: { Home },
  name: 'App',
  mounted() {
    console.log('mounted');
  }
}
</script>

<style></style>