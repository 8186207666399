<template>
    <div class="footer pt-[61px] bg-[#2d2771] px-[20px]">
        <div
            class="md:flex text-center md:text-start max-w-[1230px] mx-auto justify-between pb-[81px]"
        >
            <div>
                <p
                    class="euro-stile-std text-[#96ba64] font-[700] leading-[21px] text-[15px]"
                >
                    {{ localizationData.company }}
                </p>
                <p
                    class="euro-stile-std text-[#96ba64] font-[700] leading-[21px] text-[15px]"
                >
                    {{ localizationData.info }}
                </p>
                <p
                    class="euro-stile-std text-[#96ba64] font-[700] leading-[21px] text-[15px]"
                >
                    {{ localizationData.street }}
                </p>
                <p
                    class="euro-stile-std text-[#96ba64] font-[700] leading-[21px] text-[15px]"
                    v-html="localizationData.street_capital"
                ></p>
                <a
                    href="mailto:contact@everset.it?subject=Info"
                    class="euro-stile-std text-[#96ba64] font-[700] leading-[21px] text-[15px]"
                    >info@everset.it</a
                >
            </div>
            <div class="mt-[20px] md:pr-[70px] md:mt-0 md:flex items-center">
                <img
                    class="max-h-[81px] h-full mx-auto"
                    src="@/assets/images/Logo.png"
                    alt="logo"
                />
            </div>
            <div class="mt-[40px] md:mt-0">
                <p
                    class="euro-stile-std text-[#96ba64] font-[700] leading-[21px] text-[15px]"
                >
                    <a
                        href="/privacy-policy/it/privacy-policy.pdf"
                        target="_blank"
                        >Privacy Policy</a
                    >
                </p>
                <p
                    class="euro-stile-std text-[#96ba64] font-[700] leading-[21px] text-[15px]"
                >
                    <a
                        href="/cookie-policy/it/cookies-policy.pdf"
                        target="_blank"
                        >Cookie Policy</a
                    >
                </p>
                <div
                    class="euro-stile-std mt-[30px] text-[#96ba64] font-[700] leading-[21px] text-[15px]"
                >
                    <button @click="changeLang('it')">ITALIANO</button> /
                    <button @click="changeLang('en')">ENGLISH</button>
                </div>
            </div>
        </div>
    </div>
    <div
        class="post-footer h-[93px] bg-[#231a51] flex items-center justify-center"
    >
        <a
            href="https://www.linkedin.com/company/everset-it/jobs/"
            target="_blank"
        >
            <p
                class="text-[#96ba64] euro-stile-std-light font-[500] text-[22px] md:text-[30px] text-center"
            >
                {{ localizationData.join_us }}
            </p>
        </a>
    </div>
</template>

<script>
export default {
    props: ["localizationData"],
    methods: {
        changeLang(lang) {
            this.$store.commit("changeCountry", lang);
            location.reload();
        },
    },
};
</script>

<style></style>
