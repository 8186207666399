import { createStore } from "vuex";
let lang: string = "it";
if (localStorage.getItem("newCountry")) {
    lang = JSON.parse(JSON.stringify(localStorage.getItem("newCountry")));
}
// Create a new store instance.
const store = createStore({
    state() {
        return {
            country: lang,
        };
    },
    mutations: {
        changeCountry(state, newCountry) {
            state.country = newCountry;
            localStorage.setItem("newCountry", newCountry);
        },
    },
});

export default store;
