<template>
    <div class="leads pt-[50px] scrollable md:pt-[170px]" id="leads">
        <div class="section-content">

            <h1 class="text-[#96ba64] title-custom section-item text-center euro-stile-std text-[44px]  md:text-[63px] leading-[40px] md:leading-[56px]" v-html="localizationData.title"></h1>
            <div class="box section-item  mt-[41px] mb-[31px] bg-[#231a51] max-w-[978px] mx-auto ]">
                <div>
                    <div class="px-[45px] md:px-[112px] outline-offset-[-9px] py-[100px] outline-dashed outline-[18px]  outline-[#96ba64]">
                        <div class="max-w-[763px] ">
                            <h2 class="text-white euro-stile-std sub-custom text-[23px] md:text-[24px] pl-[25px] md:pl-0 leading-[27px]" v-html="localizationData.box.title_one">
                            </h2>
                            <p class="text-[#6062a5] text-[14px] md:text-[15px] mt-[20px] md:mt-[5px] euro-stile-std-demi leading-[18px]" v-html="localizationData.box.paragraph_one">
                            </p>
                        </div>
                    </div>

                    <div class="bg-[#96ba64] py-[80px] mt-[-2px] px-[45px] md:px-[112px] outline-[18px] outline-offset-[-9px] outline-dashed outline-[#231a51] relative z-1">
                        <div class="max-w-[763px]">

                            <h2 class="text-white euro-stile-std sub-custom text-[23px] md:text-[24px] pl-[25px] md:pl-0 leading-[27px]" v-html="localizationData.box.title_two">
                            </h2>

                            <p class="text-[#231a51] text-[14px] md:text-[15px] mt-[20px] md:mt-[5px] euro-stile-std-demi leading-[18px]" v-html="localizationData.box.paragraph_two">

                            </p>
                        </div>
                    </div>

                    <div class="px-[50px] md:px-[112px] relative mt-[-2px] outline-offset-[-9px] py-[80px] outline-dashed outline-[18px]  outline-[#96ba64]">
                        <div class="max-w-[763px]">

                            <h2 class="text-white euro-stile-std sub-custom text-[23px] md:text-[24px] pl-[25px] md:pl-0 leading-[27px]" v-html="localizationData.box.title_three">
                            </h2>
                            <p class="text-[#6062a5] text-[16px] md:text-[15px] mt-[20px] md:mt-[5px] euro-stile-std-demi leading-[18px]" v-html="localizationData.box.paragraph_three">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-[10px] section-item">
                <p class="euro-stile-std text-center text-[16px] md:text-[15px] text-[#96ba64]" v-html="localizationData.box.disclaimer">
                </p>
            </div>
            <div class="img section-item min-h-[129px] max-h-[214px] h-[100%] md:h-[100%]">
                <img class="md:max-w-[990px]   max-h-[214px] mx-auto section-image" :src="getImgUrl('4')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['localizationData'],

    methods: {
        getImgUrl(img) {
            const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (windowWidth < 768) {
                return new URL('/images/Mobile/' + img + ".png", import.meta.url)
            } else {
                return new URL('/images/Desktop/' + img + ".png", import.meta.url)

            }
        }

    }
}
</script>

<style></style>