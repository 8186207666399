<template>
    <div class="hero pt-[85px] pb-[30px] md:py-[226px] text-center" id="header">
        <div class="logo text-center">
            <img class="h-[80px] md:h-[129px] mx-auto" src="@/assets/images/Logo.png" alt="Everset.it">
        </div>
        <div class="text mt-[45px] md:mt-[31px]" id="header-text">
            <h1 class="euro-stile-std font-extrabold text-[13px] md:text-[20px] leading-[18px] md:leading-[24px] text-[#cbcbcb77] md:text-[#96ba64]" v-html="localizationData.title">

            </h1>
        </div>
        <div class="logo text-center hidden pt-[30px]" id="header-medium-img">
            <img class="h-[80px] md:h-[129px] mx-auto" src="@/assets/images/Logo_mob.png" alt="Everset.it">
        </div>
    </div>
    <div class="hero h-[30px] md:py-[226px] md:hidden text-center" id="header-sticky">
    </div>
</template>

<script>
export default {
    name: 'Hero',
    props: ['localizationData'],

}
</script>

<style></style>